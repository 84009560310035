import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Over the years I’ve discovered an array of OS X defaults that make my life easier and performing tasks quicker. One of the reasons I learnt Vim was because I was using my mouse far too much for repetitive tasks.`}</p>
    <p>{`You should know what you’re doing when using the Terminal. Don’t blame me when you break something 👊.`}</p>
    <h2>{`Developer happiness`}</h2>
    <h3>{`Faster keyboard response`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`defaults write NSGlobalDomain KeyRepeat -int 0.02
`}</code></pre>
    <h3>{`Reduced key repeat delay`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`defaults write NSGlobalDomain InitialKeyRepeat -int 12
`}</code></pre>
    <h3>{`UTF-8 Terminal happiness`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`defaults write com.apple.terminal StringEncodings -array 4
`}</code></pre>
    <h3>{`Protect your code`}</h3>
    <p>{`This might not be a developer friendly default, but you can keep your code secure when your screensaver starts and you have to leave your hot desk.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`defaults write com.apple.screensaver askForPassword -int 1
defaults write com.apple.screensaver askForPasswordDelay -int 0
`}</code></pre>
    <h2>{`Overall nice-to-haves`}</h2>
    <p>{`These aren't developer specific but really helpful. 💯`}</p>
    <h3>{`Silence your boot chime`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo nvram SystemAudioVolume=" "
`}</code></pre>
    <h3>{`Increase the Dock show/hide speed`}</h3>
    <p>{`If you have to use the Dock, speed up the time it animates in and out.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`defaults write com.apple.dock autohide -bool true
defaults write com.apple.dock autohide-time-modifier -float 0.5
killall Dock
`}</code></pre>
    <h3>{`Save/Print Modals`}</h3>
    <p>{`Expanding the Save and Print modals in OS X is a repetitive task and is something you can set to always show if you know how to.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`defaults write NSGlobalDomain PMPrintingExpandedStateForPrint -bool true

defaults write NSGlobalDomain NSNavPanelExpandedStateForSaveMode -bool true
`}</code></pre>
    <h3>{`Return of `}<inlineCode parentName="h3">{`~/Library`}</inlineCode></h3>
    <p>{`Maybe you're a frequent visitor of the ~/Library folder. I always forget the ALT shortcut when in Finder to show `}<inlineCode parentName="p">{`~/Library`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`chflags nohidden ~/Library
`}</code></pre>
    <h3>{`Create prettier screenshots in OS X`}</h3>
    <p>{`Window captures come with a shadow effect by default. `}<strong parentName="p">{`You can remove it.`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`defaults write com.apple.screencapture disable-shadow -bool true
`}</code></pre>
    <h3>{`'Are you sure you want to open this application?'`}</h3>
    <p>{`Open an application from the web without being asked if that's what you really want to do.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`defaults write com.apple.LaunchServices LSQuarantine -bool false
`}</code></pre>
    <p>{`Alternatively, you might want to allow apps downloaded from "Anywhere" allowed to be opened.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo spctl - master-disable
`}</code></pre>
    <h3>{`Disable Time Machine annoying dialogs`}</h3>
    <p>{`You'll store your documents and photos in iCloud, your code on GitHub and your music on Spotify. Time Machine doesn't need to ask you if you want to use any storage devices as a backup drive.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`defaults write com.apple.TimeMachine DoNotOfferNewDisksForBackup -bool true
`}</code></pre>
    <p>{`If you've got any other Mac OS X defaults, please leave a comment to share with others. 👌`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      